import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { StrategyParamSelect } from '@/shared/fields/StrategyParamSelect';
import { useAtom } from 'jotai';
import { NumericFormatCustom } from '@/shared/fields/NumberFieldFormat';
import { ignoreScrollEvent } from '../../../util';
import MaxClipSizeField from './AlgoOrderFieldsComponents/MaxClipSizeField';

const expiries = [
  { seconds: 60, display: '1 minute' },
  { seconds: 120, display: '2 minutes' },
  { seconds: 300, display: '5 minutes' },
  { seconds: 900, display: '15 minutes' },
  { seconds: 1800, display: '30 minutes' },
  { seconds: 3600, display: '1 hour' },
  { seconds: 10800, display: '3 hours' },
  { seconds: 21600, display: '6 hours' },
  { seconds: 43200, display: '12 hours' },
  { seconds: 86400, display: '1 day' },
  { seconds: 432000, display: '5 days' },
];

const slices = [2, 5, 10, 20, 50, 100];

function SimpleOrderFields({
  selectedAccountExchangeNames,
  selectedDuration,
  selectedStrategy,
  trajectories,
  setSelectedDuration,
  stopPrice,
  setStopPrice,
  updatePairLeverage,
  setUpdatePairLeverage,
  orderSlices,
  setOrderSlices,
  showSlices,
  strategyParams,
  FormAtoms,
}) {
  const [selectedStrategyParams, setSelectedStrategyParams] = useAtom(
    FormAtoms.selectedStrategyParamsAtom
  );

  const [maxClipSize, setMaxClipSize] = useAtom(FormAtoms.maxClipSizeAtom);

  const handleStrategyParamChange = (event) => {
    setSelectedStrategyParams({
      ...selectedStrategyParams,
      [event.target.name]: event.target.checked,
    });
  };

  const noArrowStyle = {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    'input[type=number]': {
      MozAppearance: 'textfield',
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={showSlices ? 6 : 12}>
        <FormControl fullWidth>
          <InputLabel id='select-expiry-dropdown-label'>Expiry</InputLabel>
          <Select
            id='select-expiry-dropdown'
            label='Expiry'
            labelId='select-expiry-dropdown-label'
            value={selectedDuration}
            onChange={(e) => setSelectedDuration(e.target.value)}
          >
            {expiries.map((expiry) => (
              <MenuItem key={expiry.seconds} value={expiry.seconds}>
                {expiry.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {showSlices && (
        <Grid xs={6}>
          <FormControl fullWidth>
            <InputLabel id='select-slices-dropdown-label'>Slices</InputLabel>
            <Select
              id='select-slices-dropdown'
              label='Slices'
              labelId='select-slices-dropdown-label'
              value={orderSlices}
              onChange={(e) => setOrderSlices(e.target.value)}
            >
              {slices.map((slice) => (
                <MenuItem key={slice} value={slice}>
                  {slice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {trajectories && trajectories[selectedStrategy]?.name === 'Iceberg' && (
        <MaxClipSizeField
          maxClipSize={maxClipSize}
          setMaxClipSize={setMaxClipSize}
          style={{ marginTop: '16px' }}
        />
      )}

      <Grid xs={12}>
        <StrategyParamSelect
          isSimple
          showHeading
          handleStrategyParamChange={handleStrategyParamChange}
          selectedAccountExchangeNames={selectedAccountExchangeNames}
          selectedStrategyParams={selectedStrategyParams}
          strategyParams={strategyParams}
        />
      </Grid>

      <Grid xs={6}>
        <TextField
          fullWidth
          InputProps={{ step: 'any', inputComponent: NumericFormatCustom }}
          placeholder='Stop Price'
          sx={noArrowStyle}
          value={stopPrice}
          onChange={(e) => setStopPrice(e.target.value)}
          onWheel={ignoreScrollEvent}
        />
      </Grid>

      <Grid xs={6}>
        <TextField
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
          placeholder='Update Pair Leverage'
          sx={noArrowStyle}
          value={updatePairLeverage}
          onChange={(e) => setUpdatePairLeverage(e.target.value)}
          onWheel={ignoreScrollEvent}
        />
      </Grid>
    </Grid>
  );
}

export default SimpleOrderFields;
